import React from "react"
import Layout from "../components/layout"
import GLightbox from 'glightbox';

// import "../fontawesome/css/all.min.css"

import "./car-gallery.css"
import { Cars } from "../components/cars"

const Tractorshow = () => (
  <div className="App">
    <Layout>

            <div className="container">
      <div>
        <div className="row">
          <div className="col-md-12">
            <h1>Car Gallery</h1>
               <Cars />
            <p>

            </p>
          </div>
        </div>

      </div>

      </div>

    </Layout>

  </div>



)
export default Tractorshow
