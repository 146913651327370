import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"


export const Cars = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
    allCarsfullJson {
      edges {
        node {
          id
          src {
            childImageSharp {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
            base
          }
          name
        }
      }
    }
  }

  `)


  return (



    <div className="grid-wrapper" >
      {data.allCarsfullJson.edges.map(car => (

        <div className="grid">
        <a href={'https://pcs2016.s3.amazonaws.com/photosfrom2021/' + car.node.src.base}  class="glightbox" data-glightbox="type: image" >
               <Img className="img-grid" fluid={car.node.src.childImageSharp.fluid}    style={{ height: `200px`, width: `200px`}}/>
              </a>
        </div>
      ))}
    </div>
  )
}
